import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { OrangeTitle } from "./../../../../css/Titre.module.css"

import Select from "./../../../../components/Select"
import Button from "./../../../../components/Button"

import AddImage from "./../../../../res/add_image.png"

import firebase from "gatsby-plugin-firebase"

import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Wrapper = styled.section`
  margin-top: 100px;
  margin-bottom: 100px;
  margin-left: 10vw;
  margin-right: 10vw;
  width: 80vw;

  ::placeholder {
    color: grey;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: grey;
  }

  ::-ms-input-placeholder {
    color: grey;
  }
`

const FormWrapper = styled.form`
  padding-top: 30px;
`

const ImageWrapper = styled.div`
  margin-left: calc(50% - 75px);
  width: 150px;
  height: 150px;
`

const Rounded = styled.img`
  object-fit: cover;
  border-radius: 50%;
  height: 150px;
  width: 150px;
`

const NoImageWrapper = styled.div`
  width: 150px;
  height: 150px;
  background-color: #999999;
  border-radius: 200px;
`

const RowWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Spacer = styled.section`
  flex: 1;
`

const InputWrapper = styled.section`
  padding-top: 30px;
  flex: 6;

  input {
    border: 1px solid #cccccc;
    height: 50px;
    width: 100%;
    min-width: 250px;
    border-radius: 5px;
    color: #444444;
  }

  textarea {
    padding-top: 10px;
    border: 1px solid #cccccc;
    height: 100px;
    width: 100%;
    border-radius: 5px;
    color: #444444;
  }
`

const ButtonWrapper = styled.section`
  width: 150px;
  height: 40px;
`

const Modifier = ({ job, jobs, shops }) => {
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)
  const [errorSnackbarIsOpened, setErrorSnackbarIsOpened] = useState(false)

  const [title, setTitle] = useState(job["title"])
  const [description, setDescription] = useState(job["description"])
  const [image, setImage] = useState({ preview: job["image"], raw: "" })
  const [shop, setShop] = useState({ value: job["shopId"], label: "" })
  const [contract, setContract] = useState({
    label: job["contract"],
    value: job["contract"],
  })
  const [startDate, setStartDate] = useState(job["startDate"])
  const [duration, setDuration] = useState(job["duration"])
  const [studiesLevel, setStudiesLevel] = useState(job["studiesLevel"])
  const [competencies, setCompetencies] = useState(job["competencies"])
  const [salaryRange, setSalaryRange] = useState(job["salaryRange"])
  const [wayToApply, setWayToApply] = useState({
    label: job["wayToApply"],
    value: job["wayToApply"],
  })
  const [content, setContent] = useState(job["content"])
  const [mask, setMask] = useState({
    label: job["mask"] ? "Oui" : "Non",
    value: job["mask"],
  })
  const [index, setIndex] = useState(job["index"].toString())

  const contractType = ["Alternance", "Stage", "CDD", "CDI"]
  const maskPossibilities = [
    { label: "Oui", value: true },
    { label: "Non", value: false },
  ]
  const waysToApply = ["Lien", "Mail", "Numéro de téléphone"]

  useEffect(() => {
    console.log("job[index]: ", job["index"].toString())
    console.log("index: ", index)
    let idx = 0

    while (idx < shops.length) {
      if (job["shopId"] === shops[idx]["id"]) {
        setShop({ value: shops[idx]["id"], label: shops[idx]["name"] })
        break
      }
      idx++
    }
  }, [shops, job])

  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const handleErrorClose = () => {
    setErrorSnackbarIsOpened(false)
  }

  const getNewJob = imagePath => {
    var newJob = {
      image: imagePath,
      title: title,
      contract: contract.value,
      startDate: startDate,
      duration: duration,
      studiesLevel: studiesLevel,
      competencies: competencies,
      salaryRange: salaryRange,
      wayToApply: wayToApply.value,
      content: content,
      description: description,
      shopId: shop.value,
      mask: mask.value,
      index: Number(index),
    }

    return newJob
  }

  const handleValidateFormButton = () => {
    if (job["index"].toString() !== index) {
      var idx = 0

      while (idx < jobs.length) {
        if (jobs[idx]["index"].toString() === index) {
          break
        }
        idx++
      }

      firebase
        .firestore()
        .collection("jobs")
        .doc(jobs[idx]["id"])
        .update({
          index: job["index"],
        })
        .then(() => {
          console.log("updatedOtherJobSucces")
          if (image.preview !== job["image"]) {
            firebase
              .storage()
              .ref(`/jobs/${image.raw.name}`)
              .put(image.raw)
              .then(() => {
                firebase
                  .storage()
                  .ref(`/jobs/${image.raw.name}`)
                  .getDownloadURL()
                  .then(imageUrl => {
                    firebase
                      .firestore()
                      .collection("jobs")
                      .doc(job["id"])
                      .update(getNewJob(imageUrl.toString()))
                      .then(() => {
                        setSuccessSnackbarIsOpened(true)
                      })
                      .catch(updatedJobError => {
                        //console.log("updatedJobError: ", updatedJobError)
                        setErrorSnackbarIsOpened(true)
                      })
                  })
                  .catch(getUrlError => {
                    //console.log("getUrlError: ", getUrlError)
                    setErrorSnackbarIsOpened(true)
                  })
              })
              .catch(uploadImageError => {
                //console.log("uploadImageError: ", uploadImageError)
                setErrorSnackbarIsOpened(true)
              })
          } else {
            console.log("getNewJob: ", getNewJob(image.preview))
            firebase
              .firestore()
              .collection("jobs")
              .doc(job["id"])
              .update(getNewJob(image.preview))
              .then(() => {
                setSuccessSnackbarIsOpened(true)
              })
              .catch(updatedShopError => {
                //console.log("updatedOfferError in else: ", updatedShopError)
                setErrorSnackbarIsOpened(true)
              })
          }
        })
        .catch(updatedJobError => {
          console.log("updatedOtherJobError: ", updatedJobError)
        })
    }
  }

  return (
    <Wrapper>
      <h1 className={OrangeTitle}>Modifier l'offre d'emploi</h1>
      <FormWrapper className={FormWrapper}>
        <ImageWrapper>
          <label htmlFor="upload-button">
            {image.preview ? (
              <Rounded src={image.preview} alt="job's image" />
            ) : (
              <NoImageWrapper>
                <img
                  src={AddImage}
                  alt="offer"
                  width="75"
                  height="75"
                  style={{ paddingLeft: "37px", paddingTop: "37px" }}
                />
              </NoImageWrapper>
            )}
          </label>
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleChange}
          />
        </ImageWrapper>
        <RowWrapper>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="title">Titre</label>
            </div>
            <input
              type="name"
              placeholder="ex: Livreur"
              onChange={e => setTitle(e.target.value)}
              value={title}
            />
          </InputWrapper>
          <Spacer></Spacer>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="shop">Commerce</label>
            </div>
            <Select
              placeholder="Quel commerce propose cette offre ?"
              options={shops.map(shop => ({
                value: shop.id,
                label: shop.name,
              }))}
              onChange={e => setShop(e)}
              value={shop}
            />
          </InputWrapper>
        </RowWrapper>
        <RowWrapper>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="contractType">Type de contrat</label>
            </div>
            <Select
              placeholder="Quel est le type de contrat de cette offre d'emploi ?"
              options={contractType.map(data => ({
                value: data,
                label: data,
              }))}
              onChange={e => setContract(e)}
              value={contract}
            />
          </InputWrapper>
          <Spacer></Spacer>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="startDate">Date de début</label>
            </div>
            <input
              type="text"
              placeholder="ex: 01/06/2022"
              onChange={e => setStartDate(e.target.value)}
              value={startDate}
            />
          </InputWrapper>
        </RowWrapper>
        <RowWrapper>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="duration">Durée</label>
            </div>
            <input
              type="text"
              placeholder="ex: 6 mois"
              onChange={e => setDuration(e.target.value)}
              value={duration}
            />
          </InputWrapper>
          <Spacer></Spacer>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="studiesLevel">Niveau d'études</label>
            </div>
            <input
              type="text"
              placeholder="ex: Bac +2"
              onChange={e => setStudiesLevel(e.target.value)}
              value={studiesLevel}
            />
          </InputWrapper>
        </RowWrapper>
        <RowWrapper>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="competencies">Compétences</label>
            </div>
            <input
              type="text"
              placeholder="ex: Ponctuel, polis, dynamique..."
              onChange={e => setCompetencies(e.target.value)}
              value={competencies}
            />
          </InputWrapper>
          <Spacer></Spacer>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="salaryRange">Fourchette salaire</label>
            </div>
            <input
              type="text"
              placeholder="ex: 600-700€"
              onChange={e => setSalaryRange(e.target.value)}
              value={salaryRange}
            />
          </InputWrapper>
        </RowWrapper>
        <RowWrapper>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="wayToApply">Moyen pour postuler</label>
            </div>
            <Select
              placeholder="Comment l'utilisateur pourra postuler ?'"
              options={waysToApply.map(data => ({
                value: data,
                label: data,
              }))}
              onChange={e => setWayToApply(e)}
              value={wayToApply}
            />
          </InputWrapper>
          <Spacer></Spacer>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="content">Contenu</label>
            </div>
            <input
              type="text"
              placeholder="ex: job@meetinclass.com"
              onChange={e => setContent(e.target.value)}
              value={content}
            />
          </InputWrapper>
        </RowWrapper>
        <RowWrapper>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="mask">Masquée</label>
            </div>
            <Select
              placeholder="ex: Oui"
              options={maskPossibilities.map(data => ({
                value: data.value,
                label: data.label,
              }))}
              onChange={e => setMask(e)}
              value={mask}
            />
          </InputWrapper>
          <Spacer></Spacer>
          <InputWrapper>
            <div style={{ paddingTop: "10px" }}>
              <label htmlFor="index">Index</label>
            </div>
            <input
              type="text"
              placeholder="ex: 3"
              onChange={e => setIndex(e.target.value)}
              value={index}
            />
          </InputWrapper>
        </RowWrapper>
        <InputWrapper>
          <div style={{ paddingTop: "10px" }}>
            <label htmlFor="description">Description</label>
          </div>
          <textarea
            type="message"
            placeholder="Décris l’offre ici..."
            onChange={e => setDescription(e.target.value)}
            value={description}
          />
        </InputWrapper>
        <RowWrapper style={{ paddingTop: "30px" }}>
          <Spacer></Spacer>
          <ButtonWrapper>
            <Button alt="" onClick={() => handleValidateFormButton()}>
              Modifier
            </Button>
          </ButtonWrapper>
        </RowWrapper>
      </FormWrapper>
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as bien modifié cette offre !
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Oops... Les infos que tu as rentré ne sont pas correctes.
        </Alert>
      </Snackbar>
    </Wrapper>
  )
}

export default Modifier
